import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { BsTelephone } from "react-icons/bs";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";

const Header =() =>{
    const [showOpenMenu, setShowOpenMenu] = useState(false);

    return(
        <div className='headercomponent_container' >
            <div className='headercomponent_logocontainer' >
                <img src="/images/cliquelogonobg.png" alt="Clique care logo" />
            </div>
            <div className='headercomponent_element'>
                <div  className='headercomponent_phone'>
                    <span><BsTelephone/></span>
                    <a title='click to call' href="tel:+447401439556"> +44 74 0143 9556</a>
                </div>
                <div className='headercomponent_ulcontainer'>
                    <ul className='headercomponent_ul'>
                        <li><Link to="/">Care Services</Link></li>
                        <li><Link to="/about">About Us</Link></li>
                        <li><Link to="/contact">Contact Us</Link></li>
                        <li><Link to="/">News/Blogs</Link></li>
                        <li><Link to="/">Join our team</Link></li>
                    </ul>
                    <p  onClick={()=>setShowOpenMenu(!showOpenMenu)} className="headermoobile_icon">
                        {showOpenMenu ? <AiOutlineClose/> : <BiMenuAltRight/>}
                    </p>
                </div>
                {
                    showOpenMenu && 
                    <div className="headermoobile_nav">
                        <ul>
                            <li><Link to="/">Care Services</Link></li>
                            <li><Link to="/about">About Us</Link></li>
                            <li><Link to="/contact">Contact Us</Link></li>
                            <li><Link to="/">News/Blogs</Link></li>
                            <li><Link to="/">Join our team</Link></li>
                        </ul>
                    </div>
                }

            </div>
            
        </div>
    )
}

export default React.memo(Header);