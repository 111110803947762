import React from 'react';
import { Link } from 'react-router-dom';

const Home =() =>{

    return(
        <div className='homecomponent_container' >
            <div className="hero_container">
                <div className="hero_content"> 
                    <h1>
                        Do you or your loved one need care at home?
                    </h1>
                    <div className="hero_button">
                        <Link to="/contact">Find Out more</Link>
                    </div>

                    <Link className="hero_otherlink">Are You looking for a career?</Link>
                </div>
                <div className="hero_imagecontainer">
                    <img className="hero_image" src="/images/heroimagnobg.png" alt="Clique care agency" />
                </div>
            </div>
            <div className="componnet_belowfold">
                <div>
                    <h2>At Clique Care, giving back to the community is our mantra</h2>
                    <h3>Our website is being re-developed to make it easier for us to serve you...</h3>
                    <a className='componnet_belowfoldlink' href="tel:+447401439556">CLick to Call +44 740 143 9556</a>
                </div>
            </div>
            
        </div>
    )
}

export default React.memo(Home);