import React from 'react';
import { Link } from 'react-router-dom';import { BsTelephone } from "react-icons/bs";
import { FaFacebookF ,FaYoutube, FaLinkedinIn, FaInstagram, FaTwitter} from "react-icons/fa";


const Footer =() =>{

    return(
        <div className='footer_componentcontainer' >
            <div className='footer_componentcontent'>
                <div className='footer_element'>
                    <h3>Clique Care LTD</h3>
                    <div className='footer_elementimage'><img src="/images/cliquelogo.png" alt="Clique CAre logo" /></div>
                    <div>
                        <ul>
                            <li>
                                <div className='footer_ul_div'>
                                    <span><BsTelephone/></span>
                                    <p>
                                        Clique Care, unit23, Durham business park, Kent KN34 03X
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className='footer_ul_div'>
                                    <span><BsTelephone/></span>
                                    <p>
                                        +44 74 0143 9556
                                </p>
                                </div>
                            </li>
                            <li>
                                <div className='footer_ul_div'>
                                    <span><BsTelephone/></span>
                                    <p>
                                        info@cliquecare.co.uk
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <ul className="footerlinks_socialmedia">
                                        <li>
                                            <a href="https://www.facebook.com/softwaredevbytes">
                                                <FaFacebookF/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="www.youtube.com/afeez20">
                                                <FaYoutube/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/in/afeez-badmos-19962333">
                                                <FaLinkedinIn/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/softwaredev__bytes">
                                                <FaInstagram/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.twitter.com/afeez20">
                                                <FaTwitter/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='footer_element'>
                    <ul>
                        <li>Companion Care</li>
                        <li>End Of Life Care</li>
                        <li>Home Care</li>
                        <li>Respite Care</li>
                    </ul>
                </div>
                <div className='footer_element'>
                    <ul>
                        <li>About Us</li>
                        <li>FAQ</li>
                        <li>Contact Us</li>
                        <li>Locations</li>
                    </ul>
                </div>
                <div className='footer_element'>
                    <ul>
                        <li>Careers</li>
                        <li>Careers FAQ</li>
                        <li>Vacancies</li>
                        <li>Staff Login</li>
                    </ul>
                </div>
            </div>

            <div className="footer-container">
                <p>&copy;  {`${new Date().getFullYear()}`} Global Mclien Software Solutions. </p>
                <div className='copyright_element'>
                    <p>
                        <Link to="/sitemaps"> Sitemaps</Link>
                    </p>
                    <p><Link to='/privacy'>Privacy Policy</Link>  | <Link to='/privacy'>Terms</Link>  </p>
                </div>
            </div>
           
        </div>
    )
}

export default React.memo(Footer);