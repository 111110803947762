import { Route, Routes, BrowserRouter as Router } from "react-router-dom";

import './App.css';
import Layout from "./component/layouts";
import Home from "./component/Home";
import About from "./component/About";
import Contact from "./component/Contact";
import PlainLayout from "./component/layouts/PlainLayout";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />} >
           <Route index element={<Home />} />
        </Route>


        <Route element={<PlainLayout />} >
           <Route path="/about"  element={<About />} />
           <Route path="/contact"  element={<Contact />} />
        </Route>
      </Routes>

    </Router>
  );
}

export default App;
