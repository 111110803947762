import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';



const PlainLayout =() =>{

    return(
        <>
            <Header />
            <div className="plainlayout_container">
                <Outlet />
            </div>
            <Footer />
        </>
    )
}

export default PlainLayout;
